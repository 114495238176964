<template>
	<div class="avatar_selecter" @click="avatar.picked=!avatar.picked">
		<img :src="avatar.image" class="logo">
		<div class="name label">{{ avatar.name }}</div>
		<div class="soul label">{{ avatar.soul }}</div>
		<div class="checked" v-if="avatar.picked"></div>
	</div>
</template>

<style scoped>
.avatar_selecter {
	position: relative;
	width: 90px;
	height: 120px;
	margin: 10px;
	cursor: pointer;
}
.checked {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgba(157, 157, 157, 0.5);
}
.checked:after {
	content: "\f00c";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: var(--fa-style-family, "Font Awesome 6 Free");
	font-size: 50px;
	font-weight: var(--fa-style, 900);
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	color: white;
	text-shadow: 2px 2px 2px black;
}
.logo {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.label {
	position: absolute;
	left: 0px;
	right: 0px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	color: white;
	text-shadow: 1px 1px 1px black;
}
.name {
	top: 5px;
	font-size: 16px;
	font-weight: bolder;
}
.soul {
	bottom: 5px;
	font-size: 14px;
	font-weight: light;
}
</style>

<script>
export default {
	name: 'AvatarSelecter',
	props: ["avatar"],
}
</script>