// common

window.newEle = (tag, ...attrs) => {
	var ele = document.createElement(tag);
	attrs.forEach(attr => {
		if (typeof attr === 'string') {
			attr = attr.split(/;\s*/).filter(c => !!c);
		}
		if (attr instanceof Array) {
			for (let css of attr) {
				let key = css.split(/[:=] */)[0];
				let value = css.replace(key, '').replace(/^[:=] */, '');
				key = key.replace(/[:=] */, '');
				ele.style[key] = value;
			}
		}
		else {
			for (let key in attr) {
				ele.setAttribute(key, attr[key]);
			}
		}
	});
	return ele;
};
window.newID = (len=16) => {
	var id = [];
	for (let i = 0; i < len; i ++) {
		let j = Math.floor(Math.random() * 36);
		id.push(j.toString(36));
	}
	return id.join('');
};
window.wait = (delay=0) => new Promise(res => setTimeout(res, delay));

// storage utils
const onStorageChanged = evt => {
	if (!!evt.key.match(/^chat\-/i)) return;
	if (!!window.onSettingChanged) onSettingChanged(evt);
};
localStorage.__proto__.get = function (key, def={}) {
	var item = this.getItem(key);
	if (!item) return def;
	try {
		item = JSON.parse(item);
	}
	catch (err) {
		return def;
	}
	return item;
};
localStorage.__proto__.set = function (key, value) {
	var v = value;
	value = JSON.stringify(value);
	this.setItem(key, value);
	onStorageChanged({
		key: key,
		value: v,
	});
};
window.addEventListener("storage", evt => {
	var value;
	try {
		value = JSON.parse(evt.newValue);
	}
	catch (err) {
		console.error(err);
		value = null;
	}
	onStorageChanged({
		key: evt.key,
		value
	});
});

// Symbol
Symbol.set = Symbol.setSymbols = function (host, symbols) {
	if (String.is(host)) {
		symbols = [].map.call(arguments, i => i);
		host = null;
	}
	else if (Array.is(host) && !symbols) {
		symbols = host;
		host = null;
	}
	host = host || {};
	var symb2name = {};
	var str2name = {};
	symbols.forEach(symbol => {
		symbol = symbol.split('|');
		if (symbol.length === 0) return;
		if (symbol.length < 2) symbol[1] = symbol[0];
		var name = symbol[1];
		symbol = symbol[0];
		var sym = Symbol(symbol);
		symb2name[sym] = name;
		str2name[symbol] = name;
		Object.defineProperty(host, symbol, {
			value: sym,
			configurable: false,
			enumerable: true
		});
	});
	host.toString = symbol => symb2name[symbol] || str2name[symbol] || 'No Such Symbol';
	Object.defineProperty(host, 'toString', { enumerable: false });
	return host;
};

// DB
self.DataCenter = {
	dbs: new Map(),
	_waiters: new Map(),
	waitForReady: (dbName) => new Promise(res => {
		var db = DataCenter.dbs.get(dbName);
		if (!!db && db.ready) return res();
		var list = DataCenter._waiters.get(dbName);
		if (!list) {
			list = new Set();
			DataCenter._waiters.set(dbName, list);
		}
		list.add(res);
	}),
	resumeWaiters (dbName) {
		var reqList = DataCenter._waiters.get(dbName);
		if (!reqList) return;
		var list = [...reqList];
		reqList.clear();
		DataCenter._waiters.delete(dbName);
		list.forEach(req => req());
	},
	async _initAPIData () {
		var dbName = 'APIData';
		var db = new CachedDB(dbName, 2);
		DataCenter.dbs.set(dbName, db);
		db.onUpdate(() => {
			db.open('data', 'url', 10);
			db.open('index', 'url', 10);
			console.log('DataCenter::APIData Updated');
		});
		db.onConnect(() => {
			console.log('DataCenter::APIData Connected');
		});
		await db.connect();
		DataCenter.resumeWaiters(dbName);
	},
	async _initBookShelf () {
		var dbName = 'localBookShelf';
		var db = new CachedDB(dbName, 1);
		DataCenter.dbs.set(dbName, db);
		db.onUpdate(() => {
			db.open('article', 'id');
			db.open('list', 'id', 0, ['publish']);
			console.log('BookShelf::CacheStorage Updated');
		});
		db.onConnect(() => {
			console.log('BookShelf::CacheStorage Connected');
		});
		await db.connect();
		DataCenter.resumeWaiters(dbName);
	},
	async init () {
		await Promise.all([
			DataCenter._initAPIData(),
			DataCenter._initBookShelf()
		]);
	},
	onConnect (db, callback) {
		db = DataCenter.get(db);
		if (!db) return callback(null);
		db.onConnect(callback);
	},
	onUpdate (db, callback) {
		db = DataCenter.get(db);
		if (!db) return callback(null);
		db.onUpdate(callback);
	},
	async get (dbName, store, key) {
		var db = DataCenter.dbs.get(dbName);
		if (!db) return undefined;
		if (!db.ready) {
			await DataCenter.waitForReady(dbName);
		}
		if (!db.available) return undefined;
		return await db.get(store, key);
	},
	async set (dbName, store, key, value) {
		var db = DataCenter.dbs.get(dbName);
		if (!db) return null;
		if (!db.ready) {
			await DataCenter.waitForReady(dbName);
		}
		if (!db.available) return null;
		return await db.set(store, key, value);
	},
	async all (dbName, store, key) {
		var db = DataCenter.dbs.get(dbName);
		if (!db) return null;
		if (!db.ready) {
			await DataCenter.waitForReady(dbName);
		}
		if (!db.available) return null;
		return await db.all(store, key);
	},
	async del (dbName, store, key) {
		var db = DataCenter.dbs.get(dbName);
		if (!db) return null;
		if (!db.ready) {
			await DataCenter.waitForReady(dbName);
		}
		if (!db.available) return null;
		return await db.del(store, key);
	},
	async clear (dbName, store) {
		var db = DataCenter.dbs.get(dbName);
		if (!db) return null;
		if (!db.ready) {
			await DataCenter.waitForReady(dbName);
		}
		if (!db.available) return null;
		db.clearCache(store);
		return await db.clear(store);
	},
};