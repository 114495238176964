/**
 * Name:	Auxillary Utils and Extends for DateTime
 * Desc:    日期时间相关拓展
 * Author:	LostAbaddon
 * Version:	0.0.3
 * Date:	2023.12.15
 */

(() => {
	const WeekDayNames = {
		enS: ['Sun', "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		enL: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
		zhM: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
		zhT: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
	};
	
	const getLongString = (short, len=2, isLeft=true) => {
		var long = short + '';
		while (long.length < len) {
			if (isLeft) long = '0' + long;
			else long = long + '0';
		}
		return long;
	};
	const getInfoStrings = (info, type) => {
		var short, long;
	
		if (type === 'Y') {
			long = info + '';
			short = long.substring(2);
		}
		else if (type === 'ms') {
			short = info + '';
			long = getLongString(info, 3, false);
		}
		else {
			short = info + '';
			long = getLongString(info);
		}
	
		return [short, long];
	};
	const timeNormalize = (time, format='YYYY/MM/DD hh:mm:ss') => {
		time = time || new Date();
		if ((typeof time) === 'number') time = new Date(time);
	
		var [shortYear       , longYear       ] = getInfoStrings(time.getYear() + 1900, 'Y');
		var [shortMonth      , longMonth      ] = getInfoStrings(time.getMonth() + 1, 'M');
		var [shortDay        , longDay        ] = getInfoStrings(time.getDate(), 'D');
		var [shortHour       , longHour       ] = getInfoStrings(time.getHours(), 'h');
		var [shortMinute     , longMinute     ] = getInfoStrings(time.getMinutes(), 'm');
		var [shortSecond     , longSecond     ] = getInfoStrings(time.getSeconds(), 's');
		var [shortMilliSecond, longMilliSecond] = getInfoStrings(time.getMilliseconds(), 'ms');
		var weekdayES = WeekDayNames.enS[time.getDay()];
		var weekdayEL = WeekDayNames.enL[time.getDay()];
		var weekdayZM = WeekDayNames.zhM[time.getDay()];
		var weekdayZT = WeekDayNames.zhT[time.getDay()];
	
		format = format.replace(/:wde:/g, weekdayES);
		format = format.replace(/:WDE:/g, weekdayEL);
		format = format.replace(/:wdz:/g, weekdayZM);
		format = format.replace(/:WDZ:/g, weekdayZT);
	
		if (!!format.match(/YYYY+/)) {
			format = format.replace(/YYYY+/g, longYear);
		}
		else if (!!format.match(/Y+/)) {
			format = format.replace(/Y+/g, shortYear);
		}
		if (!!format.match(/MM+/)) {
			format = format.replace(/MM+/g, longMonth);
		}
		else if (!!format.match(/M+/)) {
			format = format.replace(/M+/g, shortMonth);
		}
		if (!!format.match(/DD+/)) {
			format = format.replace(/DD+/g, longDay);
		}
		else if (!!format.match(/D+/)) {
			format = format.replace(/D+/g, shortDay);
		}
		if (!!format.match(/hh+/)) {
			format = format.replace(/hh+/g, longHour);
		}
		else if (!!format.match(/h+/)) {
			format = format.replace(/h+/g, shortHour);
		}
		if (!!format.match(/mm+/)) {
			format = format.replace(/mm+/g, longMinute);
		}
		else if (!!format.match(/m+/)) {
			format = format.replace(/m+/g, shortMinute);
		}
		if (!!format.match(/ss+/)) {
			format = format.replace(/ss+/g, longSecond);
		}
		else if (!!format.match(/s+/)) {
			format = format.replace(/s+/g, shortSecond);
		}
		if (!!format.match(/xxx+/)) {
			format = format.replace(/xxx+/g, longMilliSecond);
		}
		else if (!!format.match(/x+/)) {
			format = format.replace(/x+/g, shortMilliSecond);
		}
	
		return format;
	};
	
	window.getTimeString = timeNormalize;
}) ();