<template>
	<div class="chatroom_item">
		<span class="intro" @click="$emit('enterChatRoom', info.id)">{{ title }}</span>
		<span class="members">
			<img v-for="mem in members" :src="mem.image" />
		</span>
		<div class="list">
			<div v-for="mem in members" class="name">{{ mem.name }}</div>
		</div>
		<span class="controllers">
			<i class="fas fa-sign-in-alt" @click="$emit('enterChatRoom', info.id)"></i>
			<i class="fas fa-tools" @click="$emit('editChatRoom', info.id)"></i>
			<i class="fas fa-trash-alt" @click="$emit('deleteChatRoom', info.id)"></i>
		</span>
	</div>
</template>

<style scoped>
.chatroom_item {
	position: relative;
	display: block;
	height: 80px;
	padding: 5px;
	text-align: left;
}
.intro {
	display: inline-block;
	width: calc(70% - 100px);
	line-height: 70px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	vertical-align: top;
	cursor: pointer;
}
.members {
	position: relative;
	display: inline-flex;
	width: calc(30% + 10px);
	height: 70px;
	overflow: hidden;
}
.members img {
	object-fit: contain;
	height: 100%;
}
.list {
	display: none;
	position: absolute;
	top: 5px;
	right: calc(30% + 105px);
	width: 200px;
	padding: 10px;
	background-color: rgba(23, 23, 23, 0.6);
	backdrop-filter: blur(2px);
	box-shadow: 2px 2px 5px black;
	text-align: left;
	text-shadow: 1px 1px 1px black;
	z-index: 1;
}
.chatroom_item:hover .list {
	display: block;
}
.list .name {
	line-height: 25px;
}
.controllers {
	display: inline-block;
	width: 90px;
	height: 70px;
	vertical-align: top;
	text-align: right;
}
.controllers i {
	margin-left: 10px;
	font-size: 20px;
	line-height: 70px;
	cursor: pointer;
}
</style>

<script>
export default {
	name: 'ChatRoomEntry',
	props: ["info", "avatars"],
	data () {
		return {
			title: '',
			members: []
		}
	},
	mounted () {
		if (!!this.info.intro) {
			this.title = this.info.intro.replace(/[\n\r]+/gi, ' ');
		}
		else {
			this.title = '(空)';
		}

		if (!!this.info.avatars) {
			this.info.avatars.forEach(ava => {
				this.avatars.some(item => {
					if (item.url !== ava) return;
					var url = item.image;
					if (!url.match(/^(ht|f)tps?/i)) {
						if (url.indexOf('/') === 0) url = staticHost + url;
						else url = staticHost + '/avatar/' + url;
					}
					this.members.push({
						name: item.name,
						image: url
					});
					return true;
				});
			});
		}
	}
}
</script>