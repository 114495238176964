import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import Setting from '../package.json';
import '../public/browser.js';
import '../public/datetime.js';
import '../public/utils.js';
import '../public/lrucache.js';
import '../public/cachedDB.js';

const setting = Setting.setting;
const AsianCharExp = /[\u2E80-\u2EFF\u2F00-\u2FDF\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u3100-\u312F\u3130-\u318F\u3190-\u319F\u31A0-\u31BF\u31C0-\u31EF\u31F0-\u31FF\u3200-\u32FF\u3300-\u33FF\u3400-\u4DBF\u4DC0-\u4DFF\u4E00-\u9FFF\uA960-\uA97F\uAC00-\uD7AF\uD7B0-\uD7FF\uF900-\uFAFF\uFE10-\uFE1F\uFE30-\uFE4F\uFF00-\uFFEF]/;

document.title = Setting.name;

if (process.env.NODE_ENV === 'development') {
	console.log('[:> Development <:]');
	window.staticHost = setting.development.static;
	window.wsHost = setting.development.ws;
	window.wsChannel = setting.development.channel;
}
else {
	window.staticHost = setting.production.static;
	window.wsHost = setting.production.ws;
	window.wsChannel = setting.production.channel;
}

const readSetting = (key, def) => {
	var value = localStorage.get(key, null);
	if (value === null) {
		value = setting[def];
		localStorage.set(key, value);
	}
	return value;
};

// utils
window.convertClaudeChinese = xml => {
	xml = xml || '';
	xml = xml.replace(/^\s*|\s*$/gi, '');
	while (true) {
		let x = xml.replace(/^(\s*[,!\?;:\(\)]\s*)([\w\W]?)|([\w\W]?)(\s*[,!\?;:\(\)]\s*)([\w\W]?)|([\w\W]?)(\s*[,!\?;:\(\)]\s*)$/g, (m, op1, ed1, st2, op2, ed2, st3, op3) => {
			var st = st2 || st3 || '';
			var ed = ed1 || ed2 || '';
			var op = op1 || op2 || op3;
			if (!st.match(AsianCharExp) && !ed.match(AsianCharExp)) {
				return m;
			}

			op = op.replace(/ *([,!\?;:\(\)]) */g, (m, o) => {
				if (o === ',') return '，';
				if (o === '!') return '！';
				if (o === '?') return '？';
				if (o === ';') return '；';
				if (o === ':') return '：';
				if (o === '(') return '（';
				if (o === ')') return '）';
				return o + ' ';
			});
			return st + op + ed;
		});
		x = x.replace(/([\w\W]?)\.{3,}/g, (m, st) => {
			if (!st.match(AsianCharExp)) return m;
			return st + '……';
		});
		if (xml === x) break;
		xml = x;
	}

	xml = xml
		.replace(/！\[/gi, '![')
		.replace(/\|：?\-+：?/gi, (m) => m.replace(/：/g, ':'))
		.replace(/\[([^\[\]\(\)\n\r\t]*?)\]（([^\[\]\(\)\n\r\t]*?)）/gi, (m, a, b) => '[' + a + '](' + b + ')')
		.replace(/(\n[ \t]*)(\d+\.|\-)[ \t]*/gi, (m, a, b) => a + b + ' ')
	;
	return xml;
};
window.parseXMLV1 = xml => {
	xml = convertClaudeChinese(xml);
	xml = xml
		.replace(/^[\w\W]*<answer>/i, '')
		.replace(/<\/answer>[\w\W]*$/i, '')
		.replace(/\s*<(version|role)>[\w\W]*?<\/(version|role|\s*)?>\s*/gi, '\n\n')
		.replace(/\s*<toassistant>[\w\W]*?<\/toassistant>\s*/gi, '\n\n')
		.replace(/\s*<commands>[\w\W]*?<\/commands>\s*/gi, '\n\n')
		.replace(/\s*<plan>[\w\W]*?<\/plan>\s*/gi, '\n\n')
		.replace(/\s*<critique>[\w\W]*?<\/critique>\s*/gi, '\n\n')
		.replace(/\s*<\/?replies>\s*/gi, '\n\n')
		.replace(/\s*<\/?reply>\s*/gi, '\n\n')
		.replace(/\s*<\/?touser>\s*/gi, '\n\n')
		.replace(/\s*<questions>\s*(空|\{空\})?\s*<\/questions>\s*/gi, '\n')
		.replace(/\s*<hints>\s*(空|\{空\})?\s*<\/hints>\s*/gi, '\n')
	;

	var json = {}, list = [];
	xml = xml.replace(/<questions>\s*([\w\W]*?)\s*<\/questions>/gi, (m, inner) => {
		inner = inner.replace(/<question>\s*([\w\W]*?)\s*<\/question>/gi, (m ,i) => {
			if (!i) return '\n';
			i = i.replace(/^[\d\-\.\s]*/i, '');
			if (!i) return '\n';
			list.push(i);
			return '\n';
		});
		inner = inner.split(/[\n\r]+/).filter(i => !!i);
		inner.forEach(line => {
			line = line.replace(/^\s+|\s+$/gi, '');
			line = line.replace(/^[\d\-\.\s]*/i, '');
			if (!line) return;
			list.push(line);
		});
		return '\n';
	});
	if (list.length > 0) json.questions = list;

	list = [];
	xml = xml.replace(/<hints>\s*([\w\W]*?)\s*<\/hints>/gi, (m, inner) => {
		inner = inner.replace(/<hint>\s*([\w\W]*?)\s*<\/hint>/gi, (m ,i) => {
			if (!i) return '\n';
			i = i.replace(/^[\d\-\.\s]*/i, '');
			if (!i) return '\n';
			list.push(i);
			return '\n';
		});
		inner = inner.split(/[\n\r]+/).filter(i => !!i);
		inner.forEach(line => {
			line = line.replace(/^\s+|\s+$/gi, '');
			line = line.replace(/^[\d\-\.\s]*/i, '');
			if (!line) return;
			list.push(line);
		});
		return '\n';
	});
	if (list.length > 0) json.hints = list;

	var tagList = {};
	xml.replace(/<(\/?)([\w\W]+?)>/gi, (m, close, tag) => {
		if (!!close) return;
		tag = tag.split(/\s+/);
		tag = tag[0].toLowerCase();
		tagList[tag] = (tagList[tag] || 0) + 1;
	});
	list = Object.keys(tagList).filter(tag => tagList[tag] > 1);
	tagList = Object.keys(tagList).filter(tag => tagList[tag] === 1);
	tagList.forEach(tag => {
		var reg = new RegExp('<' + tag + '( [\w\W]*?)?>', 'gi');
		xml = xml.replace(reg, '\n' + tag + ':\n');
		reg = new RegExp('<\\/' + tag + '>', 'gi');
		xml = xml.replace(reg, '\n\n');
	});
	xml = xml.split('\n');
	xml = xml.map(line => {
		list.some(tag => {
			var reg = new RegExp('^(\\s*)<' + tag + '( [\w\W]*?)?>\\s*([\\w\\W]*)\\s*<\\/' + tag + '>\s*$', 'i');
			var m = line.match(reg);
			if (!m) return;
			if (!m[3]) return;
			line = (m[1] || '') + '- ' + m[3];
			return true;
		});
		return line;
	});
	xml = xml.join('\n');
	list.forEach(tag => {
		var reg = new RegExp('<\\/?' + tag + '( [\w\W]*?)?>', 'gi');
		xml = xml.replace(reg, '');
	});
	xml = xml.replace(/^\s+|\s+$/gi, '');
	xml = xml.split("\n");
	list = "", tagList = false;
	xml = xml.map(line => {
		if (line.match(/^\s+$/)) return "";
		return line;
	});
	xml = xml.map(line => {
		var match = line.match(/^(\s*)(\d+\.)/);
		if (!!match) {
			tagList = true;
			list = match[1];
		}
		else if (line === "") {
			tagList = false;
		}
		else if (tagList) {
			match = line.match(/^\s*([\w\W]*)$/);
			line = list + '\t' + match[1];
		}
		return line;
	});
	json.reply = xml.join('\n');

	return json;
};

// esc events
const ESCCBs = [];
window.onEsc = cb => {
	ESCCBs.push(cb);
};
window.offEsc = cb => {
	var idx = ESCCBs.indexOf(cb);
	if (idx < 0) return;
	ESCCBs.splice(idx, 1);
};
document.body.addEventListener('keyup', evt => {
	if (evt.which !== 27) return;
	ESCCBs.some(cb => cb());
});

// init
const initSocket = () => new Promise(res => {
	var script = newEle('script', {
		type: "text/javascript",
		src: staticHost + "/socket.io/socket.io.js"
	});
	script.onload = () => {
		const socket = io(wsHost);
		const callbacks = new Map();

		socket.on('__message__', msg => {
			var event = msg.event, data = msg.data, err = msg.err;
			var task = !!data ? data.task : null;

			if (event === 'updateProcess') {
				if (!global.onUpdateProcess) return;
				global.onUpdateProcess(data, err);
				return;
			}
			else if (event === 'avatarSay') {
				if (!global.onAvatarReply) return;
				global.onAvatarReply(data, err);
				return;
			}

			if (!task) return;
			var cb = callbacks.get(task);
			if (!cb) return;

			callbacks.delete(task);
			if (data.ok) {
				cb.res(data.data);
			}
			else {
				cb.rej(data.message);
			}
		});

		window.sendSocket = (event, msg) => {
			socket.emit('__message__', { event: wsChannel + event, data: msg });
		};
		window.sendRequest = (event, msg) => new Promise((res, rej) => {
			var request = {task: newID(), msg};
			callbacks.set(request.task, {res, rej});
			socket.emit('__message__', { event: wsChannel + event, data: request });
		});

		res();
	};
	script.onerror = res;

	document.head.appendChild(script);
});
const initFA = () => new Promise(res => {
	var css = newEle('link', {
		rel: "stylesheet",
		href: staticHost + "/fa/css/all.min.css"
	});
	document.head.appendChild(css);
	css.onload = res;
	css.onerror = res;
});
const initAsimovExtensions = () => new Promise(res => {
	var script = newEle('script', {
		type: "text/javascript",
		src: staticHost + '/Asimov/extensions.js'
	});
	script.onload = () => {
		console.log('AsimovExtension Loaded');
		res();
	};
	script.onerror = () => {
		console.error('Load AsimovExtension failed...');
		res();
	};

	document.head.appendChild(script);
});
const initAsimov = () => new Promise(res => {
	var script = newEle('script', {
		type: "text/javascript",
		src: staticHost + '/Asimov/markup.js'
	});
	script.onload = async () => {
		console.log('Asimov Loaded');
		await initAsimovExtensions();
		res();
	};
	script.onerror = () => {
		console.error('Load Asimov failed...');
		res();
	};

	document.head.appendChild(script);
});
const getAvatarList = async () => {
	var list = [];
	try {
		let result = window._avatarList;
		if (!!result) return;

		result = await sendRequest('/avatar', {
			event: 'list',
			noID: true
		});
		for (let url in result.avatars) {
			let info = result.avatars[url];
			info.url = url;
			list.push(info);
		}

		window._avatarList = list;

		console.log('System Version: ' + result.version);
		if (result.version !== Setting.version) {
			let reload = sessionStorage.get('reloadForVersion', 0);
			reload ++;
			sessionStorage.set('reloadForVersion', reload);
			await wait(100);
			if (reload <= 3) {
				location.reload();
			}
		}
		else {
			sessionStorage.removeItem('reloadForVersion');
		}
	}
	catch (err) {
		console.error(err);
		notify({
			title: "数字化身获取失败",
			message: err.message || err.msg || err.data || err,
			duration: 5000,
			type: "error"
		});
	}
};
window.loadFont = (name, fontfile, type, options) => {
	var tag = document.createElement('style');
	var inner = ["@font-face {"];
	inner.push("\tfont-family: '" + name + "';");
	inner.push("\tsrc: url('" + staticHost + "/font/" + fontfile + "') format('" + type + "');");
	if (!!options) {
		for (let item in options) {
			inner.push("\t" + item + ': ' + options[item] + ';');
		}
	}
	inner.push("}");
	tag.innerHTML = inner.join('\n');
	document.head.appendChild(tag);
};

// DB
window.DBNames = {
	avatar: 'Avatar',
	assistant: "Assistant",
	record: "Record",
};
const initRecordDB = async () => {
	var dbName = DBNames.record;
	var db = new CachedDB(dbName, 1);
	DataCenter.dbs.set(dbName, db);
	db.onUpdate(() => {
		db.open('self', 'name');
		db.open('avatar', 'name');
		db.open('assistant', 'name');
		console.log(`DataCenter::${dbName} Updated`);
	});
	db.onConnect(() => {
		console.log(`DataCenter::${dbName} Connected`);
	});
	await db.connect();
	DataCenter.resumeWaiters(dbName);
};
const initAvatarDB = async () => {
	var dbName = DBNames.avatar;
	var db = new CachedDB(dbName, 1);
	DataCenter.dbs.set(dbName, db);
	db.onUpdate(() => {
		db.open('history', 'id');
		console.log(`DataCenter::${dbName} Updated`);
	});
	db.onConnect(() => {
		console.log(`DataCenter::${dbName} Connected`);
	});
	await db.connect();
	DataCenter.resumeWaiters(dbName);
};
const initAssistantDB = async () => {
	var dbName = DBNames.assistant;
	var db = new CachedDB(dbName, 1);
	DataCenter.dbs.set(dbName, db);
	db.onUpdate(() => {
		db.open('history', 'id');
		console.log(`DataCenter::${dbName} Updated`);
	});
	db.onConnect(() => {
		console.log(`DataCenter::${dbName} Connected`);
	});
	await db.connect();
	DataCenter.resumeWaiters(dbName);
};

// ServiceWorker
const registerServiceWorker = async () => {
	if (!navigator.serviceWorker) return;

	if (!!window.caches) {
		let keys = await caches.keys();
		if (keys.length > 0) {
			keys.forEach(async key => {
				var cacheStorage = await caches.open(key);
				var cacheKeys = await cacheStorage.keys();
				console.log('资源缓存库 ' + key + ': ' + cacheKeys.length);
			});
		}
	}

	try {
		let sws = await navigator.serviceWorker.getRegistrations();
		console.log('Service Worker: ' + sws.length);
		for (let sw of sws) {
			if (!!sw.waiting) {
				console.log('有等待中的新版本 Service Worker');
				notify({
					title: "有新版网站中台等待更新",
					message: "新版 Service Worker 将在下次打开本页面后启用。",
					duration: 5000,
					type: "warn"
				});
			}
		}
	}
	catch (err) {
		console.error('获取已安装 Service 出错：', err);
	}

	try {
		let reg = await navigator.serviceWorker.register('/serviceworker.js');
		reg.onupdatefound = (evt) => {
			evt.target.update();
		};
		console.log('安装 Service Worker ' + (!!reg ? '成功' : '失败'));
	}
	catch (err) {
		console.error('安装本地 Service 出错：', err);
	}
};

(async () => {
	console.log('Init :: start');
	// loadFont('Quantum', 'quantum.otf', 'opentype');
	// loadFont('SpaceAge', 'spaceage.ttf', 'truetype');
	// loadFont('Prototype', 'prototype.ttf', 'truetype');
	// loadFont('AlphaSector', 'alphasector.otf', 'opentype');

	global.DefaultAIModel = readSetting("AIModel", "defaultAIModel");
	global.DefaultAILevel = readSetting("AILevel", "defaultAILevel");
	global.DefaultExpert = readSetting("AIExpert", "defaultExpert");
	global.isExperiment = ['localhost', '127.0.0.1'].includes(location.hostname);

	await Promise.all([
		initSocket(),
		initFA(),
		initAsimov(),
		registerServiceWorker(),
	]);
	console.log('Init :: System :: done');

	await Promise.all([
		initRecordDB(),
		initAvatarDB(),
		initAssistantDB(),
	]);
	console.log('Init :: DB :: done');

	await Promise.all([
		getAvatarList(),
	]);
	console.log('Init :: Info :: done');

	createApp(App).use(router).mount('#app');
}) ();