<template>
	<div class="homescreen scrollable">
		<div class="title" text="Welcome To AIVerse!"></div>
		<div class="titleLine"></div>
		<pad title="个人信息" class="SelfInfo" :expandable="true" :collapsed="true">
			<img class="selfLogo" :src="selfInfo.logo" />
			<div class="selfInfo">
				<div class="infoLine">
					<span class="cap">名字：</span>
					<span class="ctx">{{ selfInfo.name }}</span>
				</div>
				<div class="infoLine">
					<span class="cap">介绍：</span>
				</div>
				<div class="infoLine">{{ selfInfo.desc }}</div>
			</div>
			<div class="infoEditor" @click="editSelfInfo"><i class="far fa-edit"></i></div>
		</pad>
		<pad title="数字化身">
			<logo v-for="item in avatarList" clsName="item"
				:info="item"
				@activeAvatar="goto('avatar/'+item.url)"
				@showAvatar="showAvatarInfo(item.url)"
			/>
		</pad>
		<pad title="虚拟聊天室" class="nouse">
			<chatRoomEntry v-for="room in chatRoomList" :key="room.id"
				:info="room" :avatars="avatarList"
				@enterChatRoom="goto('chatroom/'+room.id)"
				@editChatRoom="onEditChatRoom"
				@deleteChatRoom="onDeleteChatRoom"
			/>
			<div class="nav" @click="newChatRoom">新建聊天室</div>
		</pad>
		<pad title="AI小游戏" class="nouse">
			<div v-for="g in gameList" class="game_entry" @click="gotoGame(g.id)">
				<div class="captain">{{ g.name }}</div>
				<div class="intro">{{ g.intro }}</div>
			</div>
		</pad>
		<pad title="数字助手" class="nouse" :expandable="true" :collapsed="false">
			<div class="nav" @click="goto('conversation')">界面开发中，点这里前往老页面。</div>
		</pad>
		<pad title="更多功能" class="nouse" :expandable="true" :collapsed="false">
			<div>正在开发中，尽情期待~~</div>
			<div class="nav" @click="goto('about')">或者点这里查看功能介绍。</div>
		</pad>
	</div>
	<div class="introduction editPanel" :show="showAvatar">
		<img class="avatar" :src="avatarInfo.logo">
		<div class="line">
			<span class="item">名字：</span>
			<span class="content">{{ avatarInfo.name }}</span>
		</div>
		<div class="line">
			<span class="item">灵魂：</span>
			<span class="content">{{ avatarInfo.AI }}</span>
		</div>
		<div class="line">
			<span class="item">介绍：</span>
			<span class="content">{{ avatarInfo.desc }}</span>
		</div>
		<div class="buttons">
			<div class="button" @click="closeAvatarInfo">关闭</div>
			<div class="button" @click="goto(avatarInfo.url)">开始聊天</div>
		</div>
	</div>
	<div class="editSelfInfo editPanel SelfInfo" :show="showEditor">
		<picker ref="AvatarPicker" class="selfLogo" :logo="selfInfo.logo" />
		<div class="selfInfo">
			<div class="infoLine">
				<span class="cap">名字：</span>
				<input ref="myName" class="name" type="text" :value="selfInfo.name">
			</div>
			<div class="infoLine">
				<span class="cap">头像：</span>
				<input ref="myLogo" class="avatar" type="text" :value="selfInfo.logo" @change="changeAvatar('logo')" @keyup.enter="changeAvatar('logo')">
			</div>
			<div class="infoLine" @click="console.log(selfInfo)">
				<span class="cap">介绍：</span>
			</div>
			<div class="infoLine">
				<textarea ref="myDesc" :value="selfInfo.desc" @change="changeAvatar('desc')"></textarea>
			</div>
			<div class="infoLine">
				<i class="fas fa-check confirm" @click="confirmModifySelfInfo"></i>
				<i class="fas fa-times cancel" @click="closeSelfInfo"></i>
			</div>
		</div>
	</div>
	<div class="newChatRoom editPanel scrollable" :show="showChatRoomCreator">
		<div class="hint">请选择和你一起聊天的数字人：</div>
		<div class="avatarList">
			<selecter v-for="avatar in avatarForChatRoom" :avatar="avatar" />
		</div>
		<div class="hint">聊天室介绍<span class="vice">（包括聊天的环境、话题、每个人的立场，等等）</span>：</div>
		<textarea ref="ChatRoomInfo" class="scrollable"></textarea>
		<div class="buttons">
			<button @click="startNewChatRoom">{{ chatRoomEditingID > 0 ? '确认修改' : '确认新建' }}</button>
			<button @click="closeChatRoomCreater">取消</button>
		</div>
	</div>
	<div v-if="showAvatar || showEditor || showChatRoomCreator" class="mask" @click="closeAllPanel"></div>
</template>

<style scoped>
.homescreen {
	position: relative;
	width: 100%;
	height: 100%;
}

.title {
	position: relative;
	width: 100%;
	height: 70px;
	margin-top: 30px;
	margin-bottom: 20px;
	font-size: 60px;
	font-weight: bolder;
}
.title::after {
	content: attr(text);
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	text-align: center;
	background-image: linear-gradient(135deg, rgb(162, 0, 255), rgb(0, 89, 255));
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	-webkit-text-fill-color: transparent;
}
.title::before {
	content: attr(text);
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	text-align: center;
	color: transparent;
	-webkit-text-fill-color: transparent;
	text-shadow: 3px 3px 8px rgba(255, 255, 255, 0.6);
}
.titleLine {
	width: 100%;
	height: 1px;
	margin-bottom: 20px;
	background-image: linear-gradient(to left, rgb(162, 0, 255), rgb(0, 89, 255));
}

.mask {
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 1;
	background-color: rgba(23, 23, 36, 0.1);
}

.editPanel {
	position: fixed;
	top: 50%;
	left: 5%;
	right: 5%;
	transform: translateY(-50%);
	z-index: 2;
	display: none;
	padding: 50px;
	background-color: rgba(15, 20, 35, 0.8);
	box-shadow: 2px 2px 5px black;
	backdrop-filter: blur(1px);
}
.editPanel[show="true"] {
	display: block;;
}

.introduction {
	padding-bottom: 150px;
	font-size: 20px;
	color: rgb(230, 230, 240);
	text-align: left;
}
.introduction .avatar {
	float: right;
	width: 100%;
	max-width: 180px;
	height: 100%;
	max-height: 240px;
	object-fit: contain;
	object-position: 50% top;
	margin-left: 10px;
}
.introduction .line {
	display: flex;
	justify-content: start;
	align-content: start;
	margin-bottom: 10px;
}
.introduction .line .item {
	display: inline-block;
	width: 100px;
	font-weight: bolder;
}
.introduction .line .content {
	width: calc(100% - 100px);
}
.introduction .buttons {
	position: absolute;
	left: 50px;
	right: 50px;
	bottom: 50px;
	text-align: center;
}
.introduction .buttons .button {
	display: inline-block;
	width: 150px;
	margin: 0px 20px;
	padding: 10px 20px;
	border-radius: 10px;
	background-color: rgba(31, 32, 64, 0.8);
	box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.3), inset 0px 0px 1px rgba(230, 230, 230, 0.6);
	font-weight: bolder;
	cursor: pointer;
}

.pad.nouse {
	color: white;
}

.editSelfInfo[show="true"] {
	display: flex;
}
.editSelfInfo .selfLogo {
	width: 150px;
	height: 180px;
}
.editSelfInfo .selfInfo {
	width: calc(100% - 200px);
	margin-left: 20px;
}
.editSelfInfo .infoLine input,
.editSelfInfo .infoLine textarea {
	padding: 2px 5px;
	background: transparent;
	border: none;
	outline: none;
	resize: none;
	color: rgb(230, 230, 245);
	font-size: 16px;
}
.editSelfInfo .infoLine input {
	width: calc(100% - 65px);
	border-bottom: 1px solid rgb(230, 230, 245);
}
.editSelfInfo .infoLine textarea {
	width: calc(100% - 10px);
	height: 100px;
}
.editSelfInfo .infoLine i.fas {
	display: inline-block;
	font-size: 36px;
	font-weight: bolder;
	cursor: pointer;
}
.editSelfInfo .infoLine i.fas.confirm {
	margin-left: calc(50% - 100px);
	color: green;
}
.editSelfInfo .infoLine i.fas.cancel {
	margin-left: 46px;
	color: red;
}

.newChatRoom {
	max-height: calc(100% - 100px);
	color: white;
	text-shadow: 1px 1px 1px black;
}
.newChatRoom .hint {
	margin-bottom: 10px;
	text-align: left;
	font-size: 20px;
	font-weight: bolder;
}
.newChatRoom .hint .vice {
	font-size: 14px;
	font-weight: lighter;
}
.newChatRoom .avatarList {
	display: flex;
	justify-content: space-evenly;
	align-content: start;
	flex-wrap: wrap;;
	margin-bottom: 10px;
}
.newChatRoom textarea {
	width: calc(100% - 50px);
	height: 250px;
	padding: 10px;
	background-color: transparent;
	border: 1px solid rgb(0, 89, 255);
	border-radius: 8px;
	outline: none;
	resize: none;
	color: white;
	text-shadow: 1px 1px 1px black;
}
.newChatRoom .buttons {
	display: flex;
	justify-content: space-evenly;
}
.newChatRoom .buttons button {
	width: 100px;
	margin: 10px;
	padding-bottom: 5px;
	background-color: transparent;
	border:none;
	border-bottom: 1px solid rgb(0, 89, 255);
	outline: none;
	color: white;
	text-shadow: 1px 1px 1px black;
	cursor: pointer;
}

.game_entry {
	padding: 10px 8px;
	border-left: 2px solid rgb(0, 89, 255);
	border-right: 2px solid rgb(162, 0, 255);
	text-align: left;
	cursor: pointer;
	/* transition: all 200ms ease-in-out; */
}
.game_entry:hover {
	padding: 10px 5px;
	border-left: 5px solid rgb(162, 0, 255);
	border-right: 5px solid rgb(0, 89, 255);
}
.game_entry .captain {
	margin-bottom: 5px;
	font-size: 20px;
	font-weight: bolder;
}

@media screen and (max-width: 650px) {
	.title {
		height: 55px;
		font-size: 45px;
	}
}
@media screen and (max-width: 480px) {
	.title {
		font-size: 36px;
		word-break: keep-all;
		white-space: nowrap;
	}
}
</style>
<style>
.pad.nouse > div {
	display: block;
}
.pad.nouse .nav {
	font-weight: bolder;
	cursor: pointer;
}
.pad.SelfInfo > div.area {
	position: relative;
	min-height: 140px;
}
.SelfInfo .selfLogo {
	margin: 10px;
	width: 100px;
	height: 120px;
	object-fit: contain;
	object-position: 50% top;
}
.SelfInfo .selfInfo {
	position: relative;
	width: calc(100% - 130px);
	margin: 0px;
	color: rgb(230, 230, 245);
	text-align: left;
}
.SelfInfo .selfInfo .infoLine {
	margin-bottom: 10px;
}
.SelfInfo .selfInfo .infoLine:last-child {
	margin-bottom: 0px;
}
.SelfInfo .selfInfo .infoLine .cap {
	margin-right: 5px;
	font-weight: bolder;
}
.SelfInfo .infoEditor {
	position: absolute;
	top: -10px;
	right: -10px;
	padding: 10px;
	color: rgb(230, 230, 245);
	font-size: 24px;
	cursor: pointer;
}
</style>

<script>
import Pad from '@/components/home/pad.vue';
import Logo from '@/components/home/logo.vue';
import Picker from '@/components/home/avatarPicker.vue';
import Selecter from '@/components/home/avatarSelecter.vue';
import ChatRoomEntry from '@/components/home/chatRoomEntry.vue';

export default {
	name: 'Home',
	components: { Pad, Logo, Picker, Selecter, ChatRoomEntry },
	data () {
		return {
			ego: '',
			selfInfo: {
				name: '无名氏',
				logo: '/avatar/user.png',
				desc: '一个素人',
			},

			avatarList: [],
			showAvatar: false,
			showEditor: false,
			avatarInfo: {},

			showChatRoomCreator: false,
			avatarForChatRoom: [],
			chatRoomList: [],
			chatRoomEditingID: -1,
			
			gameList: [],

			assistantList: [],
		}
	},
	async mounted () {
		document.title = "AIVerse";

		var [ego, info, avatars] = await Promise.all([
			DataCenter.get(DBNames.record, 'self', 'ego'),
			this.getSelfInfo(),
			this.getAvatarList(),
			this.getAIGameList(),
		]);
		
		if (!!info) {
			this.selfInfo.name = info.name || this.selfInfo.name;
			this.selfInfo.desc = info.desc || this.selfInfo.desc;
			let logo = info.logo || this.selfInfo.logo;
			if (!logo.match(/^(ht|f)tps?/i)) {
				this.selfInfo.logo = staticHost + logo;
			}
			else {
				this.selfInfo.logo = logo;
			}
		}
		else {
			this.selfInfo.logo = staticHost + this.selfInfo.logo;
		}

		this.avatarList = avatars;

		// 获取自身唯一ID
		if (!ego || ego.length > 16) {
			ego = newID(16);
			await DataCenter.set(DBNames.record, 'self', 'ego', ego);
		}
		this.ego = ego;
		console.log('Current EGO: ' + ego + ' (' + ego.length + ')');

		this.getChatRoomList();
	},
	methods: {
		async getSelfInfo () {
			return await DataCenter.get(DBNames.record, 'self', 'me');
		},
		async getAvatarList () {
			return window._avatarList;
		},
		async getChatRoomList () {
			var rooms = await sendRequest('/chatRoom', {
				event: 'list',
				ego: this.ego,
			});
			localStorage.set('ChatRoomList', rooms);

			this.chatRoomList.splice(0);
			await wait();
			this.chatRoomList.push(...rooms);
		},
		async getAIGameList () {
			var games = await sendRequest('/games', {
				event: "list",
			});
			this.gameList = games.filter(game => {
				return !!ExtraRouters.Games[game.id];
			});
			console.log(ExtraRouters.Games.GirlFriend);
		},
		goto (target) {
			this.$router.push({
				path: '/' + target,
			});
		},
		gotoGame (gid) {
			var path = ExtraRouters.Games[gid];
			if (!path) {
				notify({
					title: "游戏离家出走了！",
					duration: 2000,
					type: "error"
				});
				return;
			}
			path = path.path;
			this.$router.push({path});
		},
		closeAllPanel () {
			this.closeAvatarInfo();
			this.closeSelfInfo();
			this.closeChatRoomCreater();
		},
		showAvatarInfo (aid) {
			var info;
			this.avatarList.some((avatar) => {
				if (avatar.url === aid) {
					info = avatar;
					return true;
				}
			});
			this.avatarInfo.name = info.name;
			this.avatarInfo.AI = info.AI;
			this.avatarInfo.desc = info.introduction;
			this.avatarInfo.logo = staticHost + '/avatar/' + info.image;
			this.avatarInfo.url = 'avatar/' + aid;
			this.showAvatar = true;
		},
		closeAvatarInfo () {
			this.showAvatar = false;
		},
		editSelfInfo () {
			this.$refs.AvatarPicker.changeImage(this.selfInfo.logo);
			this.showEditor = true;
		},
		closeSelfInfo () {
			this.showEditor = false;
		},
		changeAvatar () {
			this.$refs.AvatarPicker.changeImage(this.$refs.myLogo.value);
		},
		async confirmModifySelfInfo () {
			var info = {
				name: this.$refs.myName.value,
				logo: this.$refs.AvatarPicker.getLogoUrl(),
				desc: this.$refs.myDesc.value,
				update: Date.now(),
			};

			await Promise.all([
				this.saveSelfInfoLocally(info),
				this.saveSelfInfoRemotely(info),
			]);

			this.selfInfo.name = info.name;
			this.selfInfo.desc = info.desc;
			if (!!info.logo.match(/^(ht|f)tps?:/i)) {
				this.selfInfo.logo = info.logo;
			}
			else {
				this.selfInfo.logo = staticHost + info.logo;
			}
			this.closeSelfInfo();
		},
		async saveSelfInfoLocally (info) {
			await DataCenter.set(DBNames.record, 'self', 'me', info);
			notify({
				title: "个人信息修改成功",
				duration: 2000,
				type: "success"
			});
		},
		async saveSelfInfoRemotely (info) {
			var ok = await sendRequest('/avatar', {
				event: 'updateUserInfo',
				ego: this.ego,
				info
			});
			if (ok) {
				notify({
					title: "后端已经更新个人信息",
					duration: 5000,
					type: "message"
				});
			}
		},
		newChatRoom (isEdit=false, info) {
			if (isEdit === true) {
				this.chatRoomEditingID = info.id;
			}
			else {
				isEdit = false;
				this.chatRoomEditingID = -1;
			}

			var list = [], isAll = !!location.search.match(/\ball\b/i) || location.hostname === 'localhost';
			if (isAll) {
				this.avatarList.forEach(ava => {
					if (ava.chatRoom !== false) {
						list.push(ava);
					}
				});
			}
			else {
				this.avatarList.forEach(ava => {
					if (ava.locked || ava.hidden) {
						if (ava.chatRoom === true) {
							list.push(ava);
						}
					}
					else if (ava.chatRoom !== false) {
						list.push(ava);
					}
				});
			}

			if (isEdit) {
				this.$refs.ChatRoomInfo.value = info.intro;
			}
			else {
				this.$refs.ChatRoomInfo.value = '';
			}

			this.avatarForChatRoom.splice(0);
			list.forEach(ava => {
				var picked = false;
				if (isEdit) {
					picked = info.avatars.includes(ava.url);
				}
				this.avatarForChatRoom.push({
					id: ava.url,
					image: staticHost + '/avatar/' + ava.image,
					name: ava.name,
					soul: ava.AI,
					picked,
				});
			});

			this.showChatRoomCreator = true;
		},
		async startNewChatRoom () {
			var list = [];
			list = this.avatarForChatRoom.filter(ava => ava.picked).map(ava => ava.id);
			if (list.length === 0) {
				notify({
					title: "尚未选择聊天室成员",
					message: "请勾选至少一位成员，这样才能开启聊天室",
					duration: 2000,
					type: "warn"
				});
				return;
			}

			var intro = this.$refs.ChatRoomInfo.value;
			this.closeChatRoomCreater();

			var request, hint;
			if (this.chatRoomEditingID > 0) {
				hint = '修改';
				request = {
					event: 'modify',
					ego: this.ego,
					rid: this.chatRoomEditingID,
					intro,
					avatars: list
				};
			}
			else {
				hint = '创建';
				request = {
					event: 'create',
					ego: this.ego,
					intro,
					avatars: list
				};
			}

			try {
				let info = await sendRequest('/chatRoom', request);
				if (!!info && !!info.ok) {
					notify({
						title: "聊天室已" + hint,
						duration: 1000,
						type: "success"
					});
				}
				else {
					let ctx;
					if (!!info && !!info.message) ctx = info.message;
					notify({
						title: "聊天室" + hint + "失败",
						message: ctx,
						duration: 3000,
						type: "failed"
					});
				}
			}
			catch (err) {
				let ctx = err.message || err.msg || err.data || err;
				notify({
					title: "聊天室" + hint + "失败",
					message: ctx,
					duration: 3000,
					type: "failed"
				});
			}

			this.getChatRoomList();
		},
		closeChatRoomCreater () {
			this.showChatRoomCreator = false;
		},
		onEditChatRoom (rid) {
			var info = this.chatRoomList.filter(item => item.id === rid);
			if (info.length === 0) return;
			info = info[0];
			this.newChatRoom(true, info);
		},
		async onDeleteChatRoom (rid) {
			notify({
				title: "删除聊天室中，请稍后",
				duration: 2000,
				type: "message"
			});

			try {
				await DataCenter.del(DBNames.avatar, 'history', 'chatroom-' + rid);

				let info = await sendRequest('/chatRoom', {
					event: 'delete',
					ego: this.ego,
					rid
				});
				if (!!info && !!info.ok) {
					notify({
						title: "聊天室已删除",
						duration: 1000,
						type: "success"
					});
				}
				else {
					let ctx;
					if (!!info && !!info.message) ctx = info.message;
					notify({
						title: "聊天室删除失败",
						message: ctx,
						duration: 3000,
						type: "failed"
					});
				}
			}
			catch (err) {
				let ctx = err.message || err.msg || err.data || err;
				notify({
					title: "聊天室删除失败",
					message: ctx,
					duration: 3000,
					type: "failed"
				});
			}

			var list = this.chatRoomList.filter(item => item.id !== rid);
			this.chatRoomList.splice(0);
			await wait();
			this.chatRoomList.push(...list);
		},
	},
};
</script>