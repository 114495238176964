<template>
	<div ref="EGO" class="notification" :class="position" :type="type" :stage="stage">
		<div class="close" @click="close"><i class="fas fa-times-circle"></i></div>
		<div class="title" v-if="!!title || !!message">{{!!title ? title : message}}</div>
		<div class="message" v-if="!!message && !!title">{{!!title ? message : ''}}</div>
	</div>
</template>

<style scoped>
.notification {
	position: fixed;
	z-index: 500;
	border-radius: 10px;
	border: 1px solid black;
	background-color: rgba(23, 23, 23, 0.7);
	backdrop-filter: blur(3px);
	box-shadow: 0px 0px 3px rgba(225, 225, 225, 0.2), 5px 5px 6px rgba(35, 35, 35, 0.4);
	color: rgba(248, 244, 237, 1.0);
	user-select: none;
}
.notification.top-left {
	top: 0px;
	left: 0px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}
.notification.top-middle {
	top: 0px;
	left: 50%;
	transform: translateX(-50%);
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}
.notification.top-right {
	top: 0px;
	right: 0px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}
.notification.bottom-left {
	bottom: 0px;
	left: 0px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}
.notification.bottom-middle {
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.notification.bottom-right {
	bottom: 0px;
	right: 0px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.notification[stage="waiting"] {
	visibility: hidden;
	pointer-events: none;
}
.notification[stage="pending"] {
	transition: all 800ms ease-in-out;
	pointer-events: none;
}
.notification[stage="normal"] {
	transition: all 800ms ease-in-out;
}
.notification[stage="fadeout"] {
	transition: all 800ms ease-in-out;
}
.notification[stage="fadeout"].top-left, .notification[stage="fadeout"].bottom-left {
	transform: translateX(-100%);
}
.notification[stage="fadeout"].top-right, .notification[stage="fadeout"].bottom-right {
	transform: translateX(100%);
}
.notification[stage="fadeout"].top-middle {
	transform: translate(-50%, -100%);
	opacity: 0;
}
.notification[stage="fadeout"].bottom-middle {
	transform: translate(-50%, 100%);
	opacity: 0;
}

.close {
	position: absolute;
	top: -3px;
	right: -2px;
	font-size: 20px;
	cursor: pointer;
}
.top-right .close, .bottom-right .close {
	right: auto;
	left: -2px;
}

.title {
	padding: 8px 10px 5px 10px;
	border-bottom: 1px solid black;
	text-align: left;
	font-size: 18px;
	font-weight: bolder;
	text-shadow: 1px 1px 2px rgba(23, 23, 23, 0.7);
}
.top-right .title, .bottom-right .title {
	padding-left: 25px;
}
.top-left .title, .bottom-left .title {
	padding-right: 25px;
}
.top-middle .title, .bottom-middle .title {
	padding-left: 25px;
	padding-right: 25px;
	text-align: center;
}

.message {
	padding: 10px;
	font-size: 16px;
}

.notification[type="message"] {
	background-color: rgba(93, 190, 138, 0.7);
}
.notification[type="success"] {
	background-color: rgba(86, 152, 195, 0.7);
}
.notification[type="notification"] {
	background-color: rgba(236, 78, 138, 0.7);
}
.notification[type="warn"] {
	background-color: rgba(254, 215, 26, 0.7);
	color: rgba(21, 35, 27, 1.0);
}
.notification[type="error"] {
	background-color: rgba(209, 26, 45, 0.7);
	color: rgba(43, 18, 22, 1.0);
}
.notification[type="failed"] {
	background-color: rgba(226, 225, 228, 0.7);
	color: rgba(19, 17, 36, 1.0);
}
</style>

<script>
export default {
	name: "NotifyItem",
	data () {
		return {
			stage: "waiting",
			timer: null,
		}
	},
	props: {
		idx: 0,
		position: 'top_right',
		title: '',
		message: '',
		type: 'message',
		duration: 2000,
	},
	mounted () {
		var rect = this.$refs.EGO.getBoundingClientRect();
		this.$emit('onCreated', this.idx, this, rect.width, rect.height);
	},
	methods: {
		async goPending (w, h) {
			if (this.position.indexOf('top') === 0) {
				this.$refs.EGO.style.top = '0px';
			}
			else if (this.position.indexOf('bottom') === 0) {
				this.$refs.EGO.style.bottom = '0px';
			}

			if (this.position.indexOf('left') > 0) {
				this.$refs.EGO.style.left = (0 - w) + 'px';
			}
			else if (this.position.indexOf('right') > 0) {
				this.$refs.EGO.style.right = (0 - w) + 'px';
			}
			else if (this.position.indexOf('middle') > 0) {
				if (this.position.indexOf('top') === 0) {
					this.$refs.EGO.style.top = (0 - h) + 'px';
				}
				else if (this.position.indexOf('bottom') === 0) {
					this.$refs.EGO.style.bottom = (0 - h) + 'px';
				}
			}
			await wait(100);

			this.stage = "pending";
			await wait(100);

			if (this.position.indexOf('left') > 0) {
				this.$refs.EGO.style.left = '0px';
			}
			else if (this.position.indexOf('right') > 0) {
				this.$refs.EGO.style.right = '0px';
			}
			else if (this.position.indexOf('middle') > 0) {
				if (this.position.indexOf('top') === 0) {
					this.$refs.EGO.style.top = '0px';
				}
				else if (this.position.indexOf('bottom') === 0) {
					this.$refs.EGO.style.bottom = '0px';
				}
			}

			await wait(800);
			this.stage = "normal";

			this.timer = setTimeout(() => {
				this.close();
			}, this.duration);
		},
		moveTo (top) {
			if (this.position.indexOf('top') === 0) {
				this.$refs.EGO.style.top = top + 'px';
			}
			else if (this.position.indexOf('bottom') === 0) {
				this.$refs.EGO.style.bottom = top + 'px';
			}
		},
		async close () {
			if (!!this.timer) clearTimeout(this.timer);
			this.timer = null;

			this.stage = 'fadeout';
			this.$emit('onDoomed', this.idx);

			await wait(800);
			this.$emit('onDestroy', this.idx);
		}
	}
}
</script>