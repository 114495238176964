<template>
	<div class="pad" :collapsed="expandable && collapsed">
		<div class="legend" @click="collapsed = !collapsed">{{title}}<span v-if="expandable" class="expander">
			<i v-if="collapsed" class="fas fa-caret-down"></i>
			<i v-if="!collapsed" class="fas fa-caret-up"></i>
		</span></div>
		<div class="area">
			<slot></slot>
		</div>
	</div>
</template>

<style scoped>
.pad {
	position: relative;
	margin: 55px 50px 20px 50px;
	padding: 15px;
	border: 1px solid rgb(0, 89, 255);
	border-image: linear-gradient(rgb(0, 89, 255), rgb(162, 0, 255)) 1;
	border-radius: 15px;
	border-top-left-radius: 0px;
	user-select: none;
}
.pad .legend {
	position: absolute;
	bottom: 100%;
	left: -1px;
	padding: 5px 15px;
	background-color: rgb(0, 89, 255);
	color: white;
	font-size: 20px;
	font-weight: bolder;
}
.pad .legend .expander {
	position: relative;
	margin-left: 10px;
}
.area {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: 10px;
	justify-content: space-around;
}
.pad[collapsed="true"] {
	padding-top: 0px;
	padding-bottom: 0px;
}
.pad[collapsed="true"] .area {
	display: none;
}
.pad[collapsed="true"] .legend {
	cursor: pointer;
}
</style>
<style>
.pad > .area > .item {
	flex: 1;
	min-width: 120px;
	max-width: 150px;
}
</style>

<script>
export default {
	name: 'Pad',
	props: ["title", "expandable", "collapsed"],
}
</script>