<template>
	<div class="avatar">
		<img :src="url">
		<i class="fas fa-chevron-left left" @click="moveLeft"></i>
		<i class="fas fa-chevron-right right" @click="moveRight"></i>
	</div>
</template>

<style scoped>
.avatar {
	position: relative;
}
img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
i.fas {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-size: 40px;
	color: rgba(230, 230, 245, 0.7);
	cursor: pointer;
}
i.fas:hover {
	color: rgba(230, 230, 245, 1.0);
}
i.fas.left {
	left: 0px;
}
i.fas.right {
	right: 0px;
}
</style>

<script>
var logoIndex = 0;
const logoList = ['user.png', 'h1.png', 'h2.png', 's1.png', 's2.png'];

export default {
	name: 'AvatarPicker',
	props: ["logo"],
	data () {
		return {
			url: '',
		}
	},
	mounted () {
		this.changeImage(this.logo);
	},
	methods: {
		moveLeft () {
			logoIndex --;
			if (logoIndex < 0) {
				logoIndex = logoList.length - 1;
			}
			this.changeImage(logoList[logoIndex]);
		},
		moveRight () {
			logoIndex ++;
			if (logoIndex >= logoList.length) {
				logoIndex = 0;
			}
			this.changeImage(logoList[logoIndex]);
		},
		changeImage (url) {
			if (!url.match(/^(ht|f)tps?:/i)) {
				let pos = url.indexOf('/');
				if (pos !== 0) {
					url = staticHost + '/avatar/' + url;
				}
				else {
					url = staticHost + url;
				}
			}
			this.url = url;
		},
		getLogoUrl () {
			return this.url.replace(staticHost, '');
		},
	}
}
</script>