<template>
	<Notification />
	<div class="frame">
		<router-view/>
	</div>
</template>

<style>
#app {
	width: 100%;
	height: 100%;
	background-color: rgb(127, 127, 127);
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	font-size: 16px;
	color: rgb(37, 37, 45);
}
a, a:hover, a:active, a:visited {
	color: rgb(37, 37, 45);
}
html, body {
	margin: 0px;
	padding: 0px;
	width: 100%;
	height: 100%;
}
html, body, div, p, span {
	box-sizing: border-box;
}

.frame {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 1600px;
	height: 100%;
	background-color: rgb(49, 51, 57);
	box-shadow: 1px 0px 10px rgba(56, 56, 56, 0.5), -1px 0px 10px rgba(56, 56, 56, 0.5);
	overflow: hidden;
}

.scrollable {
	overflow-x: hidden;
	overflow-y: auto;
}
.scrollable::-webkit-scrollbar {
	width: 10px;
	background-color: transparent;
	cursor: pointer;
}
.scrollable::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: rgb(75, 75, 75);
	box-shadow: inset 2px 2px 3px rgba(255, 255, 255, 0.5);
	cursor: pointer;
}
.scrollable::-webkit-scrollbar-track {
	border-radius: 5px;
	background-color: rgba(225, 225, 225, 0.1);
	box-shadow: inset 2px 2px 3px rgba(53, 53, 53, 0.2);
	cursor: pointer;
}

.markup h1 {
	margin-top: 50px;
	margin-bottom: 30px;
	font-size: 2rem;
}
.markup h2 {
	margin-top: 50px;
	margin-bottom: 30px;
	font-size: 1.6rem;
}
.markup a {
	color: inherit;
}
.markup p {
	margin-top: 15px;
	margin-bottom: 15px;
	line-height: 1.5rem;
}
.markup p:last-child {
	margin-bottom: 0px;
}
.markup p:empty {
	display: none;
}
.markup pre p, .markup code p, .markup ul p, .markup ol p, .markup blockquote p:first-child {
	margin-top: 0px;
}
.markup pre p, .markup code p, .markup ul p:last-child, .markup ol p:last-child {
	margin-bottom: 0px;
}
.markup blockquote {
	margin: 0px;
	margin-top: 15px;
	margin-bottom: 15px;
	padding: 10px;
	padding-right: 5px;
	background-color: rgba(23, 23, 23, 0.6);
	border-left: 5px solid black;
	word-break: break-word;
}
.markup pre {
	margin: 10px;
	padding: 10px;
	background-color: black;
	border-left: 5px solid rgba(227, 227, 227, 0.6);
	line-height: 18px;
	word-wrap: normal;
	word-break: keep-all;
	white-space: pre;
	overflow: auto;
}
.markup code {
	margin-left: 5px;
	margin-right: 5px;
	font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
	font-size: 16px;
	color: #fad689;
}
.markup pre code {
	margin-left: 0px;
	margin-right: 0px;
}
.markup ul, .markup ol {
	margin-top: 15px;
	margin-bottom: 15px;
}
.markup ul ol, .markup ol ol, .markup ul ul, .markup ol ul {
	margin-top: 0px;
	margin-bottom: 0px;
}
.markup ul p+ol, .markup ol p+ol, .markup ul p+ul, .markup ol p+ul {
	position: relative;
	top: -12px;
}
.markup li.aiHint {
	display: inline-block;
	margin-bottom: 5px;
	margin-right: 10px;
	cursor: pointer;
}
.markup li.aiHint p {
	display: inline-block;
	padding: 2px 5px;
	border-radius: 5px;
	border: 1px solid white;
	pointer-events: none;
}
.markup table {
	empty-cells: show;
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 0px;
	border: 1px solid black;
	border-spacing: 0;
	border-radius: 5px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
}
.markup table th,
.markup table td {
	padding: 5px 8px;
	border-bottom: 1px solid black;
}
.markup table tr:last-child td {
	border-bottom: none;
}
.markup table th {
	background-color: rgba(255, 255, 255, 0.06);
}
</style>

<script>
import Notification from '@/components/notification/index.vue';

export default {
	name: "App",
	components: {Notification},
};
</script>