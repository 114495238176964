<template>
	<Item ref="Items" v-for="item in list" :idx="item.idx"
		:position="item.position" :type="item.type" :duration="item.duration"
		:title="item.title" :message="item.message"
		@onCreated="onItemCreated" @onDoomed="onItemDoomed" @onDestroy="onItemDestroy" />
</template>

<style scoped>
</style>

<script>
import Item from '@/components/notification/item.vue';

const AvailablePosition = {
	"top-left": "top-left",
	"top-middle": "top-middle",
	"top-right": "top-right",
	"bottom-left": "bottom-left",
	"bottom-middle": "bottom-middle",
	"bottom-right": "bottom-right",
};
const AvailableType = {
	"message": "message",
	"popup": "popup",
	"notification": "notification",
	"success": "success",
	"failed": "failed",
	"warn": "warn",
	"error": "error",
};

export default {
	name: "Notification",
	components: { Item },
	data () {
		return {
			list: [],
			topL: [],
			topM: [],
			topR: [],
			botL: [],
			botM: [],
			botR: [],
		}
	},
	mounted () {
		this._index = 1;
		window.notify = (message, title, position="top-right", type="message", duration=2000) => {
			var item;
			if (typeof message === 'object') item = message;
			else item = {message, title, position, type, duration};

			item.position = AvailablePosition[(item.position || 'top-right').toLowerCase()] || 'top-right';
			item.type = AvailableType[(item.type || 'popup').toLowerCase()] || 'popup';
			item.duration = item.duration || 2000;
			if (isNaN(item.duration)) item.duration = 2000;
			item.idx = this._index;
			this._index ++;

			this.list.push(item);
		};
	},
	methods: {
		getList (position) {
			if (position === 'top-left') return this.topL;
			else if (position === 'top-middle') return this.topM;
			else if (position === 'top-right') return this.topR;
			else if (position === 'bottom-left') return this.botL;
			else if (position === 'bottom-middle') return this.botM;
			else if (position === 'bottom-right') return this.botR;
		},
		async onItemCreated (idx, ele, width, height) {
			var item;
			this.list.some(i => {
				if (i.idx === idx) {
					item = i;
					return true;
				}
			});
			if (!item) return;

			item._width = width;
			item._height = height;

			var target = this.getList(item.position), value = height;
			for (let ele of target) {
				let ui = this.$refs.Items.filter(i => i.idx === ele.idx);
				if (ui.length < 1) continue;
				ui = ui[0];
				ui.moveTo(value);
				value += ele._height;
			}
			target.unshift(item);

			await ele.goPending(width, height);
		},
		async onItemDoomed (idx) {
			var item;
			this.list.some(i => {
				if (i.idx === idx) {
					item = i;
					return true;
				}
			});
			if (!item) return;
			var target = this.getList(item.position);
			var value = target.indexOf(item);
			if (value < 0) return;
			target.splice(value, 1);

			value = 0
			for (let ele of target) {
				let ui = this.$refs.Items.filter(i => i.idx === ele.idx);
				if (ui.length < 1) continue;
				ui = ui[0];
				ui.moveTo(value);
				value += ele._height;
			}
		},
		onItemDestroy (idx) {
			if (this.topL.length + this.topM.length + this.topR.length + this.botL.length + this.botM.length + this.botR.length === 0) {
				this.list.splice(0);
			}
		}
	}
}
</script>