import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';

const GameRouters = {
	GirlFriend: 	{
		path: '/game/girlfriend',
		name: 'GirlFriend',
		component: function () {
			return import('./games/girlfriend.vue')
		}
	},
};

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/company',
		name: 'Company',
		component: function () {
			return import('./views/Company.vue')
		}
	},
	{
		path: '/console',
		name: 'Console',
		component: function () {
			return import('./views/Console.vue')
		}
	},
	{
		path: '/conversation',
		name: 'Conversation',
		component: function () {
			return import('./views/Conversations.vue')
		}
	},
	{
		path: '/chat',
		redirect: '/',
	},
	{
		path: '/chat/:chatID(\\w+)',
		name: 'Chat',
		props: true,
		component: function () {
			return import('./views/Chat.vue')
		}
	},
	{
		path: '/avatar',
		redirect: '/avatar/yalinaG',
	},
	{
		path: '/avatar/:avatarID(\\w+)',
		name: 'Avatar',
		props: true,
		component: function () {
			return import('./views/Avatar.vue')
		}
	},
	{
		path: '/chatroom/:roomID(\\w+)',
		name: 'ChatRoom',
		props: true,
		component: function () {
			return import('./views/ChatRoom.vue')
		}
	},
	{
		path: '/about',
		name: 'About',
		component: function () {
			return import('./views/About.vue')
		}
	},
	{
		path: '/404',
		name: 'PageNotFound',
		component: function () {
			return import('./views/About.vue')
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'PageNotFound',
		component: function () {
			return import('./views/About.vue')
		}
	}
];

routes.push(...(Object.values(GameRouters)));

window.ExtraRouters = {};
ExtraRouters.Games = GameRouters;

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;