<template>
	<div class="avatar_logo" :class="clsName" :hidden="hidden" :locked="locked">
		<div class="avatar_area">
			<div class="image" :style="background"></div>
			<div class="name">{{info.name}}</div>
		</div>
		<div class="info">
			<div class="line">灵魂：{{info.AI}}</div>
			<div class="panel">
				<div class="viewInfo" @click="$emit('showAvatar', info.url)"><i class="fas fa-id-card"></i></div>
				<div class="enterChat" @click="$emit('activeAvatar', info.url)"><i class="far fa-comments"></i></div>
			</div>
		</div>
		<div class="locked"><i class="fas fa-user-lock"></i></div>
	</div>
</template>

<style scoped>
.avatar_logo {
	position: relative;
	user-select: none;
	cursor: pointer;
}
.avatar_logo[locked="true"] {
	pointer-events: none;
}
.avatar_area {
	position: relative;
	width: 120px;
	margin-left: auto;
	margin-right: auto;
}
.image {
	width: 120px;
	height: 120px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	border: 1px solid rgba(15, 20, 35, 1.0);
}
.name {
	padding: 5px 8px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	background-color: rgba(15, 20, 35, 1.0);
	font-size: 18px;
	font-weight: bolder;
	color: white;
	white-space: nowrap;
	word-wrap: keep-all;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;;
}
.info {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	padding: 10px;
	background-color: rgba(49, 51, 57, 0.6);
	border: 1px solid rgba(15, 20, 35, 1.0);
	color: white;
	font-size: 0.9em;
	text-align: left;
	opacity: 0;
}
.avatar_logo:hover .info {
	opacity: 1;
}
.info .line {
	margin-bottom: 5px;
	white-space: nowrap;
	word-wrap: keep-all;
	overflow: hidden;
	font-size: 0.9em;
	text-overflow: ellipsis;
}
.info .panel {
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	padding: 5px;
	font-size: 20px;
}
.info .panel .viewInfo {
	position: absolute;
	bottom: 0px;
	left: 0px;
}
.info .panel .enterChat {
	position: absolute;
	bottom: 0px;
	right: 0px;
}
.locked {
	display: none;
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgba(49, 51, 57, 0.6);
	backdrop-filter: blur(3px);
}
.avatar_logo[locked="true"] .locked {
	display: block;
}
.locked i {
	margin-top: 45px;
	font-size: 36px;
	color: white;
}
</style>

<script>
export default {
	name: 'Logo',
	props: ["info", "clsName"],
	data () {
		return {
			name: '',
			background: {},
			locked: false,
			hidden: false,
		}
	},
	mounted () {
		if (!this.info) return;
		this.background.backgroundImage = "url('" + staticHost + "/avatar/" + this.info.image + "')";
		var isAll = !!location.search.match(/\ball\b/i) || location.hostname === 'localhost';
		this.locked = !isAll && this.info.locked;
		this.hidden = !isAll && this.info.hidden;
	}
}
</script>